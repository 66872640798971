import React from 'react'
import {createBrowserRouter, Outlet, useNavigate} from 'react-router-dom'

import {Main} from '.'
import {Login} from './user/login'
import {storage} from '../lib/storage'
import {MovieList} from './homeMedia/movies'
import {TVSeries} from './homeMedia/series'
import {config} from '../config'
import ErrorBoundary from '../errorBoundary'

function AuthComponent() {
  const navigate = useNavigate()

  React.useEffect(() => {
    const token = storage.get('authToken')
    if (!token && !config.isDev && !config.isLocal) {
      return navigate('/')
    }
  }, [])

  return (
    <div>
      <Outlet />
    </div>
  )
}

export const router = createBrowserRouter([
  {
    element: <AuthComponent />,
    children: [
      {
        path: '/',
        element: <Login />,
        errorElement: <div>Error Page! Try again later!</div>
      },
      {
        path: '/test',
        element: <div>Test Here</div>
      },
      {
        element: (
          <div>
            <div>Navigation In Progress</div>
            <Outlet />
          </div>
        ),
        children: [
          {
            path: '/expenses',
            element: (
              <ErrorBoundary>
                <Main />
              </ErrorBoundary>
            )
          },
          {
            path: '/home-media/:lang/movies',
            element: <MovieList />
          },
          {
            path: '/home-media/:lang/series',
            element: <TVSeries />
          }
        ]
      }
    ]
  }
])
