const baseURL = process.env.API_URL || 'http://localhost:3000'
const localAddresses = (process.env.LOCAL_IPS || '').split(',')
//const baseURL = process.env.NODE_ENV === 'development'
  //? 'http://192.168.1.8:3000'
  //: 'http://suzanlong-api.duckdns.org'
export const config = {
  isDev: process.env.NODE_ENV === 'development',
  expenseApiUrl: `${baseURL}/expenses`,
  userApiUrl: `${baseURL}/user`,
  homeMediaApiUrl: `${baseURL}/videos`,
  isLocal: localAddresses.includes(window.location.hostname)
}
